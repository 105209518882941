import { EXPERIMENT_INSTALL_SITE_SEARCH } from '@wix/communities-blog-experiments';
import { SEARCH_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import experiments from './experiments';
import maIntegrationKit from './ma-integration-kit';
import actions from './actions';
import concurentEditing from './concurent-editing';
import paidPostMigration from './paid-post-migration';
import { blogAppDefId } from '../constants/apps';
import menu from './menu';
import pageService from './page';
import {
  MANAGE_POSTS_EVENT,
  DELETE_BLOG_EVENT,
  APP_ACTION_EVENT,
  APP_ACTION_EVENTS,
  COMPONENT_ADDED_TO_STAGE,
  INSTANCE_CHANGED,
} from '../constants/events';
import getAppManifest from './app-manifest';
import { OFFLINE_PAID_POST_MIGRATION_ID } from '../constants/migrations';
import { TPA_PAGE_ID_BLOG, TPA_PAGE_ID_POST } from '../constants/tpa-pages';
import translation from './translation';
import translations from '../translations.json';
import { AUTOPILOT_USER_UUID } from '../constants/users';
import { getSiteMemberId } from './instance';
import { addEventListeners } from './event-listeners';
import { migrateFeedDesignProps } from './migrate-feed-design-props';
import { enableConfigurableLineClamping } from './line-clamping';
import { savePostPageStyle } from './post-page-style';
import { removeInvalidPageInADI } from './remove-invalid-page';
import monitoring from './monitoring';
import { registerMemberPages } from './members-area/register-member-pages';
import { BLOG_WIDGET_ID } from '../constants/widgets';
import { IS_BLOG_MENU_SEARCH_ENABLED, IS_BLOG_MENU_SEARCH_ENABLED_MOBILE } from '@wix/communities-blog-client-common';
import { getComponentRef } from './sdk-utils';

const { translate } = translation;
const ADD_PANEL_DEEPLINK = 'addPanel';

const installSiteSearch = async ({ sdk, appToken, isADI }) => {
  if (isADI || !experiments.isEnabled(EXPERIMENT_INSTALL_SITE_SEARCH)) {
    return Promise.resolve();
  }

  await concurentEditing.withApproval(async () => {
    const isSearchInstalled = await sdk.document.tpa.isApplicationInstalled(appToken, {
      appDefinitionId: SEARCH_APP_ID,
    });

    if (isSearchInstalled) {
      return Promise.resolve();
    }

    const compRef = await getComponentRef(sdk, BLOG_WIDGET_ID);
    await sdk.document.tpa.setStyleParams(appToken, {
      compRef,
      styleParams: [
        {
          type: 'boolean',
          key: IS_BLOG_MENU_SEARCH_ENABLED,
          param: { value: false },
        },
        {
          type: 'boolean',
          key: IS_BLOG_MENU_SEARCH_ENABLED_MOBILE,
          param: { value: false },
        },
      ],
    });

    return sdk.document.tpa.add.application(blogAppDefId, {
      appDefinitionId: SEARCH_APP_ID,
    });
  });
};

export const handleEditorReady = async (context) => {
  await experiments.conduct();
  const locale = await context.sdk.environment.getLocale();
  translation.init(translations, locale);
  concurentEditing.init(context);

  await monitoring.toMonitored(
    'register-member-pages',
    concurentEditing.withApproval(() => registerMemberPages(context)),
  );

  try {
    await removeInvalidPageInADI(context);
  } catch (e) {}

  if (context.isADI) {
    return Promise.resolve();
  }

  return Promise.all([
    concurentEditing.withApproval(() => menu.addPages(context)),
    concurentEditing.withApproval(async () => {
      await migrateFeedDesignProps(context);
      await actions.setStyleParams(context);
      await enableConfigurableLineClamping(context);
    }),
    addEventListeners(context),
  ]);
};

export const handleBlogInstalled = async ({ sdk, appToken, isADI, installMembersArea, biData }) => {
  await experiments.conduct();
  const provisionInitialized = await actions.initProvision({ sdk });

  await actions.initBiService({ sdk, appToken });

  if (provisionInitialized) {
    try {
      await savePostPageStyle({ sdk, appToken });
    } catch (e) {}
  }

  if (installMembersArea === false) {
    await installSiteSearch({ sdk, appToken, isADI });
    return Promise.resolve();
  }

  const isMAInstalled = await maIntegrationKit.isMembersAreaInstalled();
  const userId = await getSiteMemberId(sdk, appToken);
  const isAutopilot = userId === AUTOPILOT_USER_UUID;

  if (isAutopilot) {
    return Promise.resolve();
  }

  if (!isMAInstalled) {
    await actions.displayProvisioningModal({
      sdk,
      appToken,
      isADI,
      biData,
    });
    await installSiteSearch({ sdk, appToken, isADI });
  }
};

const openManagePostsDashboard = (sdk, appToken) =>
  sdk.editor.openDashboardPanel(appToken, { url: 'blog?referrer=app-manager', closeOtherPanels: false });

export const handleOnEvent = async ({ eventType, eventPayload }, context) => {
  if (!context.sdk) return;
  switch (eventType) {
    case MANAGE_POSTS_EVENT:
      return openManagePostsDashboard(context.sdk, context.appToken);
    case APP_ACTION_EVENT:
      switch (eventPayload && eventPayload.actionId) {
        case APP_ACTION_EVENTS.CREATE_POST:
          context.sdk.editor.openDashboardPanel(context.appToken, {
            url: 'blog/create-post',
            closeOtherPanels: false,
          });
          break;
        case APP_ACTION_EVENTS.MANAGE_POSTS:
          openManagePostsDashboard(context.sdk, context.appToken);
          break;
        case APP_ACTION_EVENTS.OPEN_BLOG_PAGES_PANEL:
          await actions.openBlogPagesPanel(context);
          break;
        case APP_ACTION_EVENTS.OPEN_POST_PAGE_SETTINGS:
          actions.openPostPageSettings(
            context.sdk,
            context.appToken,
            translate('app-manifest.app-descriptor.post-settings-title'),
          );
          break;
        case APP_ACTION_EVENTS.OPEN_BLOG_ADD_PANEL:
          context.sdk.editor.deeplink.show(context.appToken, { type: ADD_PANEL_DEEPLINK, params: [blogAppDefId] });
          break;
        default:
          break;
      }
      break;

    case DELETE_BLOG_EVENT:
      return monitoring.toMonitored('delete-blog', deleteBlog(context));

    case COMPONENT_ADDED_TO_STAGE:
      return concurentEditing.withApproval(() => enableConfigurableLineClamping(context));
    case INSTANCE_CHANGED:
      context.instance = eventPayload.instance;
      break;
    default:
      break;
  }
};

async function deleteBlog(context) {
  const blogPage = await pageService.find({ ...context, tpaPageId: TPA_PAGE_ID_BLOG });

  if (!blogPage) {
    const postPage = await pageService.find({ ...context, tpaPageId: TPA_PAGE_ID_POST });

    if (!postPage) {
      return;
    }

    return context.sdk.document.pages.remove(context.appToken, { pageRef: { id: postPage.id } });
  }

  return context.sdk.document.pages.remove(context.appToken, { pageRef: { id: blogPage.id } });
}

export const handleGetAppManifest = ({ sdk, appToken }) => {
  if (!sdk) {
    return {};
  }

  return getAppManifest(sdk.info.getSdkVersion().scriptSrc, appToken);
};

export const handleMigrateAction = (context, { migrationId } = {}) => {
  switch (migrationId) {
    case OFFLINE_PAID_POST_MIGRATION_ID:
      return paidPostMigration.migrateOffline(context);
    default:
      return Promise.resolve();
  }
};

export default {
  handleBlogInstalled,
  handleEditorReady,
  handleOnEvent,
  handleGetAppManifest,
  handleMigrateAction,
};
